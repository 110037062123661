.loader {
    display: none;
    position: fixed;
    height: 100vh;
    pointer-events: none;
    background: rgba(0, 0, 0, .7);
    left: 0;
    right: 0;
    z-index: 3;

    &.active {
        display: block;
    }

    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        width: 10vw;
        margin: auto;
    }
}