#login {
	// position: absolute;
	// top: 50%;
	// transform: translateY(-50%);
	// left: 0;
	// right: 0;
	text-align: center;
	padding: 2vw 0;

	@media screen and (max-width: 767px) {
		margin: auto 5%;
	}

	h2,
	p {
		margin: 0;
		// line-height: 1;
	}

	h2 {
		color: var(--color-secondary);
		// font-weight: bold;
		// font-family: agencyb;

		span {
			font-family: GroldMedium;
			color: #fff;
			font-size: inherit;
		}

		@media screen and (max-width: 767px) {
			font-size: 6vw;
			margin-bottom: 2vw;
		}
	}

	p {
		color: #fff;
		font-size: 1.5vw;

		span {
			margin: 0 0.2vw;
		}

		@media screen and (max-width: 767px) {
			font-size: 4vw;
			line-height: 1.5;
		}
	}

	.login_cont {
		display: flex;
		align-items: center;
		justify-content: center;

		.login_btn {
			img {
				width: 20vw;
				cursor: pointer;

				@media screen and (max-width: 767px) {
					width: 40vw;
				}

				&:hover {
					filter: brightness(1.2);
				}
			}
		}
	}
}
