#closed {
	padding: 0 2vw;

	h1 {
		padding: 5vw;
		color: #fff;

		@media screen and (max-width: 767px) {
			font-size: 9vw;
		}
	}

	.max_team {
		font-weight: bold;
	}
}
