// @import "reset";
@import "fonts";

$yellow: #ffa41a;
$gray: #707070;
$red: #e20c0c;

html,
body,
#root {
	height: 100%;
}

html {
	width: 100%;
	padding: 0;

	* {
		font-family: GroldLight;
	}

	h1,
	h2,
	h3 {
		font-family: GroldMedium;
		margin: 0;
		line-height: 1.2;
	}

	&::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	&::-webkit-scrollbar-button {
		width: 0px;
		height: 0px;
	}
	&::-webkit-scrollbar-thumb {
		background: #e1e1e1;
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #ffffff;
	}
	&::-webkit-scrollbar-thumb:active {
		background: #000000;
	}
	&::-webkit-scrollbar-track {
		background: #666666;
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	&::-webkit-scrollbar-track:hover {
		background: #666666;
	}
	&::-webkit-scrollbar-track:active {
		background: #333333;
	}
	&::-webkit-scrollbar-corner {
		background: transparent;
	}
}

.parent-container {
	background-image: url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffim2021fall/march/bg_main.jpg"),
		url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffim2021fall/march/bg_long.jpg");
	background-position: top center;
	background-repeat: no-repeat, repeat-y;
	background-attachment: scroll, fixed;
	background-size: 100%;
	position: relative;

	@media screen and (max-width: 767px) {
		background-image: url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffim2021fall/march/bg_main.jpg"),
			url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffim2021fall/march/bg_long.jpg");
		background-size: 240%, 100%;
	}
}

img {
	width: 100%;
}

.logo {
	text-align: center;
	min-height: 20vw;
	margin-top: 1vw;
	// margin-bottom: -3vw;

	img {
		width: 21vw;

		@media screen and (max-width: 767px) {
			width: 50vw;
		}
	}
}

.follow-us {
	color: #fff;
	font-style: italic;
	margin: 0 0.8vw;
}

.parent-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.content-container {
	flex: 1 0 auto;
	padding: 0 2vw;
	text-align: center;
	margin: 0 5%;
	position: relative;
	min-height: 23vw;

	@media screen and (max-width: 767px) {
		margin: 0;
	}
}

footer {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-image: url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffim2021fall/footer.jpg");
	background-size: cover;
	background-position: bottom center;
	width: 100%;
	padding: 4vw 10%;

	@media screen and (max-width: 767px) {
		text-align: center;
		padding: 6vh 0;
		flex-direction: column;
		align-items: center;
	}

	.left {
		img {
			width: 13vw;
			position: relative;
		}

		@media screen and (max-width: 767px) {
			img {
				width: 70%;
				margin: auto;
				margin-bottom: 4vw;
			}
		}
	}

	.center {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media screen and (max-width: 767px) {
			margin: 2vh 0;
		}

		p {
			font-size: 1vw;
			margin: 0;
			text-align: center;
			color: #fff;

			@media screen and (max-width: 767px) {
				font-size: 4vw;
				margin: 0 10%;
			}
		}
	}

	.right {
		a {
			display: inline-block;
			margin-left: 0.5vw;
			cursor: pointer;

			img {
				width: 3vw;
			}
		}

		@media screen and (max-width: 767px) {
			margin-top: 3vw;
			margin-bottom: 1vw;

			a {
				margin: 0;
				text-align: center;

				img {
					width: 80%;
				}
			}
		}
	}
}

#waiting {
	// position: absolute;
	// top: 50%;
	// transform: translateY(-50%);
	// left: 0;
	// right: 0;
	padding: 28vh 0;
	color: #fff;
	margin: auto 10%;

	@media screen and (max-width: 767px) {
		padding: 13vh 0;
	}
}

.popover-body {
	background-color: var(--color-secondary);
	color: #000;
}

.arrow::after {
	border-right-color: var(--color-secondary) !important;
}

.toast {
	top: 2vw;
}

@import "loader";
@import "closed";
@import "login";
@import "daftar";
@import "success";
@import "modal";
