@font-face {
	font-family: _italic;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffml/fonts/segoeuii.ttf");
}

@font-face {
	font-family: _bold;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffml/fonts/segoeuib.ttf");
}

@font-face {
	font-family: _basic;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffml/fonts/segoeui.ttf");
}

@font-face {
	font-family: agencyr;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffml/fonts/agencyr_0.ttf");
}

@font-face {
	font-family: agencyb;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffml/fonts/agencyb_0.ttf");
}

@font-face {
	font-family: agencyfb;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffml/fonts/AgencyFB_Black.ttf");
}

@font-face {
	font-family: GroldLight;
	src: url("https://cdngarenanow-a.akamaihd.net/webid/FF/fonts/Grold-Light.ttf");
}

@font-face {
	font-family: GroldMedium;
	src: url("https://cdngarenanow-a.akamaihd.net/webid/FF/fonts/Grold-Medium.ttf");
}
