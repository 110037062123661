#daftar {
	padding-bottom: 5vw;

	.title-cont {
		margin: 3vw 0;
		@media screen and (max-width: 767px) {
			margin: 3vw auto 8vw;
		}

		img {
			width: 21vw;

			@media screen and (max-width: 767px) {
				width: 50vw;
			}
		}

		h1 {
			color: $yellow;
			font-size: 3vw;
			margin: 0;
			letter-spacing: 4px;
			line-height: 1;

			@media screen and (max-width: 767px) {
				font-size: 5vw;
			}

			&.title {
				color: #fff;
			}

			&.subtitle {
				font-family: GroldLight;
				color: var(--color-primary);
			}

			@media screen and (max-width: 767px) {
				line-height: 1.2;
			}
		}

		h3 {
			color: #fff;
			font-family: agencyb;

			@media screen and (max-width: 767px) {
				font-size: 5vw;
				line-height: 1.2;
			}
		}

		.logout-cont {
			margin-top: 1vw;
			font-size: 2vw;

			@media screen and (max-width: 767px) {
				font-size: 5vw;
			}

			* {
				color: #fff;
			}

			button {
				margin-left: 0.6vw;
				color: var(--color-secondary);
				text-decoration: underline;
				cursor: pointer;
				background: transparent;
				outline: none;
				border: none;
			}
		}
	}

	.form-container {
		.form-cont {
			text-align: initial;
			margin-bottom: 5vw;
			padding: 0;

			@media screen and (max-width: 767px) {
				margin-bottom: 13vw;
			}

			.content {
				background-color: #fff;
				padding: 4% 4%;

				@media screen and (max-width: 767px) {
					padding: 5vw;
				}

				.row {
					padding: 0 3%;
					margin: 0;
				}

				.form-group {
					margin-bottom: 1vw;

					@media screen and (max-width: 767px) {
						padding: 1vw;
					}

					label {
						font-size: 1.5vw;
						display: flex;
						align-items: center;

						@media screen and (max-width: 767px) {
							font-size: 4vw;
						}

						img {
							width: 1vw;
							margin-left: 0.5vw;
							margin-top: -0.3vw;

							@media screen and (max-width: 767px) {
								width: 3.5vw;
								margin-left: 2vw;
							}
						}
					}

					.validate_btn {
						border-radius: 0;
						font-family: GroldMedium;
						font-size: 1vw;

						&:before {
							content: attr(data-text-bf);
						}

						&.completed {
							&:before {
								content: "✔";
							}
						}

						@media screen and (max-width: 767px) {
							height: calc(1.8rem + 2px);
							padding: 0 4vw;
							font-size: 2.5vw;
						}
					}

					input {
						border: 0;
						border-bottom: 1px solid;
						border-radius: 0;

						&:disabled,
						&[readonly] {
							border: 0;
							border-bottom: 1px solid;
						}

						@media screen and (max-width: 767px) {
							font-size: 3vw;
							// height: calc(1.8rem + 2px);
						}
					}
				}
			}

			> h5 {
				color: #000;
				text-align: center;
				text-transform: uppercase;
				margin: 0;

				span {
					display: inline-block;
					font-family: GroldMedium;
					width: 20%;
					padding: 0.5vw 0;
					background-color: var(--color-secondary);

					@media screen and (max-width: 767px) {
						width: 50%;
					}
				}

				@media screen and (max-width: 767px) {
					text-align: center;
				}
			}
		}

		.discord-row {
			padding-top: 3% !important;
			padding-bottom: 2% !important;
			background-color: #7289da;
			border-radius: 30px;
			margin-top: 1vw !important;

			@media screen and (max-width: 767px) {
				border-radius: 10px;
				padding-bottom: 3% !important;
			}

			label {
				color: #fff;
			}

			input {
				background: transparent;
				color: #fff;
			}

			.btn-download-discord {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background: #ffffff;
				padding: 1vw;
				border-radius: 8px;
				text-decoration: none;
				margin-top: 1vw;

				@media screen and (max-width: 767px) {
					margin-top: 3vw;
					padding: 2vw;
				}

				img {
					width: 2vw;

					@media screen and (max-width: 767px) {
						width: 5vw;
					}
				}

				span {
					color: #000;
					font-weight: bold;
					margin-left: 1vw;

					@media screen and (max-width: 767px) {
						margin-left: 2vw;
						font-size: 3vw;
					}
				}
			}
		}
	}

	.understanding-cont {
		padding: 2vw 0;

		@media screen and (max-width: 767px) {
			margin-top: -7vw;
			padding: 3vw 6vw;
		}

		.form-group {
			padding: 1% 0;
			// background-color: $gray;
			margin: 0;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			text-align: left;

			label {
				font-size: 1.5vw;
				// padding-left: 1vw;
				color: #000;
				margin: 0;
				width: 93%;
				margin-top: -0.4vw;
				color: #fff;

				a {
					margin-left: 0.5vw;
					color: var(--color-secondary);
				}
			}

			@media screen and (max-width: 767px) {
				// padding: 3vw 6vw;

				label {
					font-size: 3vw;
					text-align: left;
					// margin-left: 3vw;
				}
			}
		}
	}

	.button-cont {
		a,
		button {
			display: inline-block;
			background-color: var(--color-primary);
			color: #000;
			width: 100%;
			padding: 1vw 0;
			border-radius: 10px;
			text-decoration: none;
			cursor: pointer;
			font-size: 1.5vw;
			font-weight: bold;
		}

		@media screen and (max-width: 767px) {
			a,
			button {
				margin-top: 2vw;
				display: block;
				font-size: 3vw;
			}
		}

		img {
			cursor: pointer;

			&:hover {
				filter: brightness(1.2);
			}
		}

		button {
			background: var(--color-secondary);
			border: none;
			&:hover {
				filter: brightness(1.2);
			}
		}
	}
}
