.modal-base {
	.modalHeader,
	.modalBody,
	.modalFooter {
		text-align: center;
	}

	svg {
		fill: var(--color-secondary);
	}

	&.error {
		// max-width: 40% !important;
	}

	&.errorGeneral {
		min-width: 25%;
	}

	&.error,
	&.errorGeneral {
		background: url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/ffim2021fall/bg_main.jpg") no-repeat center /
			cover;
		background-image: var(--img-bg-main);

		h1 {
			line-height: 1;

			&:nth-of-type(1) {
				color: var(--color-secondary);
			}

			&:nth-of-type(2) {
				color: #fff;
			}
		}

		p {
			margin-top: 1vw;
			color: #fff;
		}

		img {
			width: 60%;
		}

		.button {
			background-color: transparent;
			border: 0;
			padding: 0;
			cursor: pointer;
			margin: 1vw auto;
		}
	}
}
