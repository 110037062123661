#success {
	margin: 3vw 20%;
	left: 0;
	right: 0;

	@media screen and (max-width: 767px) {
		margin: 5vw 10% 5vw;
	}

	h1 {
		margin-bottom: 1vw;
		font-family: _bold;
		color: #fff;

		y {
			font-family: _bold;
			color: var(--color-secondary);
			margin-right: 0.5vw;
		}

		@media screen and (max-width: 767px) {
			font-size: 6vw;
		}
	}

	p {
		color: #fff;

		@media screen and (max-width: 767px) {
			margin: 3vw auto;
			font-size: 3.8vw;
		}
	}

	img {
		width: 50%;
		margin-bottom: 1vw;

		@media screen and (max-width: 767px) {
			width: 100%;
			margin-bottom: 7vw;
		}
	}

	.btn {
		border: none;
		border-radius: 0;
		color: #000;
		font-family: _bold;
		background-color: #fff;
	}

	.discord_url {
		color: var(--color-secondary);
	}
}
